<template>
  <div id="campaign-details-page">
    <vs-popup classContent="popup-example" :title="current === 'discount' ? $t('discount.add') : $t('discount.apply')" :active.sync="showModal">
      <template v-if="current === 'discount'">
        <div class="grid grid-cols-2 gap-4">
          <v-select class="col-span-2 mb-3" v-validate="'required'" :options="depTypes" :label="`name_${$i18n.locale}`" name="depType" :placeholder="$t('discount.selectDep')" v-model="discount.dep_type" :reduce="opt => opt.value" />
          <span class="text-danger text-sm mb-3" v-show="errors.has('depType')">{{ errors.first('depType') }}</span>
          
          <flat-pickr v-validate="'required'" v-model="discount.from" :config="configdateTimePicker" class="w-full inputx mb-3" name="from" :placeholder="$t('discount.selectStartDate')" />
          <flat-pickr v-validate="'required'" v-model="discount.to" :config="configdateTimePicker" class="w-full inputx mb-3" name="to" :placeholder="$t('discount.selectEndDate')" />
          <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
          <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
          
          <v-select class="mb-3" v-validate="'required'" :options="types" :label="`name_${$i18n.locale}`" name="type" :placeholder="$t('discount.type')" v-model="discount.type" :reduce="opt => opt.value" />
          <vs-input v-if="discount.type !== 'bonused'" class="w-full inputx mb-1" :placeholder="$t('discount.amount')" v-model="discount.amount" />
          <div v-else class="vx-row px-4">
            <vs-input class="w-1/2 inputx pr-2 mb-1" :placeholder="$t('discount.required')" v-model="discount.required" />
            <vs-input class="w-1/2 inputx pl-2 mb-1" :placeholder="$t('discount.bonus')" v-model="discount.bonus" />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>

        <div class="flex mt-5">
          <!-- <template v-if="editingDiscount">
            <vs-button @click="updateDiscount(discount.uuid)" color="primary" class="mt-5 ml-auto" type="filled">Update</vs-button>
            <vs-button @click="deleteDiscount(discount.uuid)" color="danger" class="mt-5 ml-4 mr-auto" type="border">Delete</vs-button>
          </template> -->
          <vs-button @click="addDiscount(campaign.id)" color="primary" class="mt-5 ml-auto" type="filled">{{ $t('submit') }}</vs-button>
        </div>
      </template>
      <template v-if="current === 'items'">
        <div class="mb-5">
          <v-select class="" multiple v-validate="'required'" :options="campaign.products" :label="`name_${$i18n.locale}`" name="type" :placeholder="$t('selectProducts')" v-model="toApply.products" />
        </div>
        <div class="mb-5">
          <v-select class="" multiple v-validate="'required'" :options="campaign.collections" :label="`name_${$i18n.locale}`" name="type" :placeholder="$t('selectCombos')" v-model="toApply.collections" />
        </div>
        <div class="flex mt-5">
          <vs-button @click="applyCampaignItems(campaign.uuid)" color="primary" class="mt-5 ml-auto" type="filled">Submit</vs-button>
        </div>
      </template>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <vs-alert color="danger" class="mb-4" :active.sync="isPassive">
        <span>{{ $t('campaign') }} {{ $t('isNotActive') }}</span>
      </vs-alert>
      <vx-card class="mb-6">
        <vs-row class="mb-3">
          <h3 class="mr-auto">{{ campaign[`title_${$i18n.locale}`] }}</h3>
          <template v-if="$acl.check('admin')">
            <div v-if="appliable && campaign.discount" class="btn-delete p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="applyToItems()">
              <feather-icon icon="PercentIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base">{{ $t('discount.apply') }}</span>
            </div>
            <div v-if="!campaign.discount" class="btn-delete p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="createDiscount()">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base">{{ $t('discount.add') }}</span>
            </div>
            <div v-else class="btn-delete p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteDiscount()">
              <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base">{{ $t('discount.delete') }}</span>
            </div>
            <router-link :to="{name: 'campaignEdit', params: {id: campaign.uuid}}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t('edit') }}</span>
            </router-link>
            <div class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteData(campaign.uuid)">
              <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base">{{ $t('delete') }}</span>
            </div>
          </template>
        </vs-row>
        <vs-table stripe :data="Object.keys(campaign)">
          <template slot="thead">
            <vs-th>{{$t('property')}}</vs-th>
            <vs-th>{{$t('value')}}</vs-th>
          </template>
          <template>
            <vs-tr v-if="campaign.image_isAdded" class="w-full">
              <vs-td class="">
                <span class="uppercase font-medium">{{ $t('props.image') }}</span>
              </vs-td>
              <vs-td class="">
                <div class="flex flex-row overflow-x-auto" style="width: 100%;">
                  <div class="img-container mr-3 relative p-4">
                    <img :src="`${$baseUrl}/campaigns/original/${campaign.uuid}.webp`" alt="" class="h-40">
                  </div>
                </div>
              </vs-td>
            </vs-tr>
            <vs-tr v-for="(prop, idx) in Object.keys(campaign)" :key="idx">
              <vs-td>
                <span class="uppercase font-medium">{{$t(`props.${prop}`)}}:</span>
              </vs-td>
              <vs-td v-if="campaign[prop] === true || campaign[prop] === false">
                <vs-chip :color="campaign[prop] ? 'success' : 'danger'">
                  <feather-icon v-if="campaign[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td v-else-if="prop === 'brand' || prop === 'category'">
                <span class="font-medium">{{ campaign[prop][`name_${$i18n.locale}`] }} </span>
              </vs-td>
              <vs-td v-else-if="prop === 'products'">
                <router-link v-for="item in campaign[prop]" :key="item.id" :to="`/products/${item.uuid}`" class="font-medium">{{ item[`name_${$i18n.locale}`] }}, </router-link>
              </vs-td>
              <vs-td v-else-if="prop === 'collections'">
                <router-link v-for="item in campaign[prop]" :key="`combo${item.id}`" :to="`/combo-products/${item.uuid}`" class="font-medium">{{ item[`name_${$i18n.locale}`] }}, </router-link>
              </vs-td>
              <vs-td v-else-if="prop === 'updatedAt' || prop === 'createdAt'">
                <span class="font-medium">{{ new Date(campaign[prop]).toLocaleString('ru-RU') }}</span>
              </vs-td>
              <vs-td v-else-if="prop === 'discount' && campaign.discount">
                <p class="font-medium">
                  <vs-chip color="danger">
                    {{ campaign.discount.dep_type | capitalize }} discount
                  </vs-chip>
                  <vs-chip color="danger">
                    <span v-if="campaign.discount.type === 'bonused'">{{ campaign.discount.required }} + {{ campaign.discount.bonus }}</span>
                    <span v-else-if="campaign.discount.type === 'percentage'">-{{ campaign.discount.amount }}%</span>
                    <span v-else>-{{ campaign.discount.amount }}TMT</span>
                  </vs-chip>
                </p>
                <div class="font-medium">
                  {{ new Date(campaign.discount.from).toLocaleString('ru-RU') }} - {{ new Date(campaign.discount.to).toLocaleString('ru-RU') }}
                </div>
              </vs-td>
              <vs-td v-else>{{ campaign[prop] }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </template>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
export default {
  components: {
    flatPickr: () => import('vue-flatpickr-component'),
    vSelect: () => import('vue-select')
  },
  data () {
    return {
      configdateTimePicker: {
        enableTime: true,
        time_24hr: true,
        locale: Russian
      },
      campaign: {
        uuid: '',
        collection_products: [],
        isActive: true,
        products: [],
        collections: []
      },
      discount: {
        type: null,
        dep_type: null,
        from: null,
        to: null,
        amount: null,
        required: null,
        bonused: null
      },
      toApply: {
        products: [],
        collections: []
      },
      types: [
        {value: 'percentage', name_en: 'Percentage', name_tm: 'Protsent', name_ru: 'Процент'},
        {value: 'price', name_en: 'Fix Price', name_tm: 'Kesgitli baha', name_ru: 'Стабильная стоимость'},
        {value: 'bonused', name_en: 'Bonus', name_tm: 'Bonus', name_ru: 'Бонусы'}
      ],
      depTypes: [
        {value: 'both', name_en: 'Both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {value: 'market', name_en: 'Market', name_tm: 'Market', name_ru: 'Маркет'},
        {value: 'express', name_en: 'Express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ],
      newImg: null,
      showModal: false,
      current: '',
      products: [],
      collections: [],
      isLoading: true
    }
  },
  computed: {
    isPassive () {
      return !this.campaign.isActive
    },
    appliable () {
      return this.campaign.products.length || this.campaign.collections.length
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.$http.get(`/campaigns/${this.$route.params.id}`).then(response => {
        this.campaign = response.data
        if (response.data.discount) this.discount = response.data.discount
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.notify(this.$t('notify.error'), err.message)
      })
    },
    async removeImg (id) {
      await this.$http.delete(`/collections/delete-image/${id}`).then(() => {
        this.notify('Success', this.$t('notify.success'), this.$t('notify.sDeleted'))
        this.fetchData()
      }).catch(err => { this.notify(this.$t('notify.error'), err.message) })
    },
    createDiscount () {
      this.current = 'discount'
      this.showModal = true
    },
    addDiscount (id) {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.discount.from = new Date(this.discount.from)
          this.discount.to = new Date(this.discount.to)
          this.$http.post('/campaigns/discount/add', {
            ...this.discount,
            campaignId: id
          }).then(response => {
            if (response.status === 201) {
              this.notify('Success', this.$t('notify.success'), this.$t('notify.sCreated'))
              this.showModal = false
              this.fetchData()
            }
          }).catch(err => { this.notify(this.$t('notify.error'), err.message) })
        } else {
          this.notify(this.$t('notify.error'), this.$t('notify.formInvalid'))
        }
      })
    },
    deleteDiscount () {
      this.$http.delete(`/campaigns/discount/delete/${this.campaign.discount.uuid}`).then(response => {
        if (response.status === 200) {
          this.notify('Success', this.$t('notify.success'), this.$t('notify.sDeleted'))
          this.fetchData()
        }
      }).catch(err => { this.notify(this.$t('notify.error'), err.message) })
    },
    async applyToItems () {
      this.current = 'items'
      this.showModal = true
    },
    async applyCampaignItems (id) {
      if (this.toApply.products.length || this.toApply.collections.length) {
        let items = this.toApply.products.map(el => { return {type: 'product', id: el.id} })
        items = items.concat(this.toApply.collections.map(el => { return {type: 'collection', id: el.id} }))
        await this.$http.post(`/campaigns/discount/apply/${id}`, {
          items
        }).then(() => {
          this.fetchData()
          this.showModal = false
        })
      } else {
        this.showModal = false
      }
    },
    deleteData (id) {
      this.$http.delete(`/campaigns/delete/${id}`).then(() => {
        this.notify('Success', this.$t('notify.success'), this.$t('notify.sDeleted'))
        this.$router.push({ name: 'campaigns' })
      }).catch(err => { this.notify(this.$t('notify.error'), err.message) })
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'Success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'Success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>